<template>
  <div class="web-header-page">
    <div class="header-content">
      <div class="leftbox">
        <div class="logo-image">
          <!-- <img src="/img/lnxde.png" v-if="marchPath('energy')"/> -->
          <img src="https://cdn.lnxall.com/logo%402x.png"/>
        </div>
      </div>
      <div class="rightbox">
        <div class="menu-box">
          <div class="item-menu" @click="handleUrl('index')">
            首页 <span class="squrea" v-if="marchPath('index')"></span>
          </div>
          <div class="item-menu" @click="handleUrl('carbon')">
            零碳数智园区 <span class="squrea" v-if="marchPath('carbon')"></span>
          </div>
          <div class="item-menu" @click="handleUrl('energy')">
            数智能源 <span class="squrea" v-if="marchPath('energy')"></span>
          </div>
          <div class="item-menu" @click="handleUrl('basis')">
            数智化基础 <span class="squrea" v-if="marchPath('basis')"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "webHeader",
  data() {
    return {};
  },
  mounted() {},
  filters: {},
  created() {
  },
  methods: {
    handleUrl(url) {
      this.$router.push({ path: url });
    },
    marchPath(value) {
      let url = this.$route.path;
      return url.indexOf(value) > -1 ? true : false;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="scss">
.web-header-page {
  box-sizing: border-box;
  height: 140px;
  position: absolute;
  left: 0;
  right: 0;

  z-index: 99;
  .header-content {
    width: 1200px;
    max-width: 1200px;
    height: 140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .leftbox {
      position: relative;
      .logo-image {
        width: 135px;
        height: 90px;
        position: absolute;
        top: 32px;
        img {
          width: 100%;
        }
      }
    }
    .rightbox {
      position: relative;
      .menu-box {
        top: 64px;
        right: 0;
        width: 560px;
        position: absolute;
        display: flex;
        .item-menu {
          margin-left: 70px;
          text-align: right;
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #fff;
          position: relative;
          .squrea {
            position: absolute;
            bottom: -8px;
            display: inline-block;
            border: 1px solid #fff;
            left: 50%;
            right: 50%;
            width: 20px;
            margin: auto;
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
