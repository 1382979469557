<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import webFooter from "./components/webFooter";
import webHeader from "./components/webHeader";
export default {
  name: "App",
  components: {
    webFooter,webHeader
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
  #app{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
  }
</style>
