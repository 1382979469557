<template>
  <div class="page-body">
    <div class="page-body-content">
      <div class="selection-part-one">
        <titleBar title="云电池管理系统" :other="true"></titleBar>
        <div class="content">
          <p>
            云电池管理系统是简捷基于物联网、边缘计算、AI技术打造的新一代移动电池包管理的端到端解决方案，可为客户提供移动电池包全方位保护、资产管理、极简运维等服务。目前已在超过50万个电池包中得以应用。
          </p>
        </div>
        <div class="part-one-content">
          <div class="left-content">
            <div class="title">智慧服务云平台</div>
            <div class="image-content">
              <img src="https://cdn.lnxall.com/img11.png" alt="" srcset="" />
            </div>
            <div class="left-arrow">物联网接入</div>
            <img
              class="left-arrow-img"
              src="https://cdn.lnxall.com/img12.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="right-content">
            <div class="title">边缘计算</div>
            <div class="content-box">
              <div class="item-list">
                <div
                  class="content"
                  style="
                    background: url('https://cdn.lnxall.com/img13.png') -24px -3px
                      no-repeat;
                    background-size: 229px 297px;
                  "
                >
                  <div class="subtitle">电池包</div>
                </div>
              </div>
              <div class="item-list">
                <div
                  class="content"
                  style="
                    background: url('https://cdn.lnxall.com/img14.png') -24px -3px
                      no-repeat;
                    background-size: 229px 297px;
                  "
                >
                  <div class="subtitle">二轮车</div>
                </div>
              </div>
              <div class="item-list">
                <div
                  class="content"
                  style="
                    background: url('https://cdn.lnxall.com/img15.png') -24px -3px
                      no-repeat;
                    background-size: 229px 297px;
                  "
                >
                  <div class="subtitle">车载后装</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-full-body-content" style="height: 460px">
      <div class="title-content">方案优势</div>
      <div class="leet-full-content">
        <div class="leet-body">
          <div
            class="leet-item"
            style="
              background: url('https://cdn.lnxall.com/img16.png') -24px -8px no-repeat;
            "
          >
            <div class="sub-title">安全保障</div>
            <div class="intro">
              温度、电压、电流检测与保护，电流检测与保护，电池均衡（主动均衡与被动均衡），高速响应短路保护
            </div>
          </div>
          <div
            class="leet-item"
            style="
              background: url('https://cdn.lnxall.com/img16.png') -24px -8px no-repeat;
            "
          >
            <div class="sub-title">超低损耗</div>
            <div class="intro">智能保护板支持超低功耗休眠及唤醒</div>
          </div>
          <div
            class="leet-item"
            style="
              background: url('https://cdn.lnxall.com/img16.png') -24px -8px no-repeat;
            "
          >
            <div class="sub-title">极速适配</div>
            <div class="intro">
              极速适配个大电池厂家的36V、48V、60V二轮车电池，一次性调试，永久使用
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="margin-top: 0; height: 410px; background-color: #ffffff"
    >
      <div class="leet-full-content" style="margin-top: 80px">
        <div class="leet-body" style="padding: 0 200px">
          <div
            class="leet-item"
            style="
              background: url('https://cdn.lnxall.com/img16.png') -24px -8px no-repeat;
            "
          >
            <div class="sub-title">资产保护</div>
            <div class="intro">
              极速寻包，通过实时定位、轨迹记录与回放、电池锁电等功能极速找包，防止资产流失
            </div>
          </div>
          <div
            class="leet-item"
            style="
              background: url('https://cdn.lnxall.com/img16.png') -24px -8px no-repeat;
            "
          >
            <div class="sub-title">极简运维</div>
            <div class="intro">
              可视化智能监控，电池包集中管理，极大降低运维成本
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-full-body-content"
      style="
        margin-top: 0;
        height: 560px;
        background: url('https://cdn.lnxall.com/img17.png') no-repeat; background-size: 100% 100%;
      "
    >
      <div class="leet-full-content">
        <div class="title-content" style="margin-top: 80px">方案价值</div>
        <div class="plane-content">
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/img18.png" alt="" srcset="" />
            </div>
            <div class="des">安全可靠</div>
          </div>
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/img19.png" alt="" srcset="" />
            </div>
            <div class="des">降本增效</div>
          </div>
          <div class="plane-item">
            <div class="imagebox">
              <img src="https://cdn.lnxall.com/img20.png" alt="" srcset="" />
            </div>
            <div class="des">极简运维</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "@/components/titleBar";
export default {
  name: "battery-page",
  components: {
    titleBar,
  },
  data() {
    return {
      active: 1,
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    onTabChange(value) {
      this.active = value;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="scss">
.page-body {
  box-sizing: border-box;
  background-color: #ffffff;
  .page-body-content {
    margin: 0 auto;
    width: 1200px;
    max-width: 1200px;
    box-sizing: border-box;
    border: 1px solid transparent;
    .selection-part-one {
      height: 454px;
      padding: 76px 0 60px 0;
      .content {
        p {
          overflow-wrap: break-word;
          color: #222222;
          font-size: 22px;
          letter-spacing: 1px;
          line-height: 48px;
          text-align: left;
          margin-top: 40px;
        }
      }
      .part-one-content {
        height: 282px;
        display: flex;
        margin-top: 40px;
        .left-content {
          width: 600px;
          position: relative;
          .title {
            width: 180px;
            height: 33px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 24px;
            letter-spacing: 1.600000023841858px;
            white-space: nowrap;
            line-height: 33px;
            text-align: right;
            position: relative;
          }
          .title::before {
            position: absolute;
            content: "";
            width: 24px;
            height: 4px;
            background-color: #247777;
            top: -16px;
            left: 0;
          }
          .image-content {
            position: absolute;
            top: 90px;
            width: 492px;
            height: 294px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .left-arrow {
            position: absolute;
            height: 28px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #247777;
            font-size: 20px;
            letter-spacing: 1.3333333730697632px;
            white-space: nowrap;
            line-height: 28px;
            text-align: right;
            top: 210px;
            right: 54px;
          }
          .left-arrow-img {
            width: 87px;
            height: 71px;
            position: absolute;
            top: 190px;
            right: 0;
          }
        }
        .right-content {
          width: 600px;
          position: relative;
          .title {
            width: 180px;
            height: 33px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 24px;
            letter-spacing: 1.600000023841858px;
            white-space: nowrap;
            line-height: 33px;
            position: relative;
          }
          .title::before {
            position: absolute;
            content: "";
            width: 24px;
            height: 4px;
            background-color: #247777;
            top: -16px;
            left: 0;
          }
          .content-box {
            display: flex;
            margin-top: 75px;
            box-sizing: border-box;
            .item-list {
              width: 200px;
              box-sizing: border-box;
              height: 254px;
              display: flex;
              justify-content: center;
              .content {
                width: 181px;
                height: 254px;
                box-sizing: border-box;
                border-radius: 12px;
                position: relative;
                .subtitle {
                  text-align: center;
                  position: absolute;
                  bottom: 40px;
                  left: 0;
                  right: 0;
                  color: #222222;
                  font-size: 22px;
                  letter-spacing: 1.4666666984558105px;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
    .selection-part-two {
      border: 1px solid red;
    }
  }
  .page-full-body-content {
    border: 1px solid transparent;
    background-color: #f4f8f8;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    .title-content {
      text-align: center;
      height: 42px;
      color: #222222;
      font-size: 30px;
      letter-spacing: 2px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 42px;
      margin-top: 170px;
    }
    .plane-content {
      display: flex;
      justify-content: space-around;
      .plane-item {
        width: 300px;
        height: 425px;
        .imagebox {
          width: 100%;
          text-align: center;
          margin-top: 116px;
          img {
            width: 85px;
            height: 84px;
          }
        }
        .des {
          margin-top: 55px;
          text-align: center;
          height: 40px;
          color: #222222;
          font-size: 28px;
          letter-spacing: 1px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 40px;
        }
      }
    }
    .leet-full-content {
      width: 1202px;
      max-width: 1202px;
      min-width: 1202px;
      margin: 0 auto;
      .leet-body {
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        .leet-item {
          height: 250px;
          border-radius: 8px;
          box-shadow: 0px 16px 24px 0px rgb(183 188 205 / 20%);
          background-size: 428px 298px;
          width: 380px;
          .sub-title {
            width: 100%;
            text-align: center;
            height: 30px;
            display: inline-block;
            overflow-wrap: break-word;
            color: #222222;
            font-size: 22px;
            font-family: PingFangSC-Medium;
            line-height: 30px;
            margin-top: 100px;
          }
          .intro {
            width: 320px;
            height: 90px;
            overflow-wrap: break-word;
            color: #555555;
            font-size: 18px;
            line-height: 30px;
            margin: 10px auto;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
