import countTo from "./countTo.js";

const directives = {
  countTo
};

export default {
  install(Vue) {
    Object.keys(directives).forEach(item => {
      Vue.directive(item, directives[item]);
    });
  }
};
