<template>
  <div class="page-body">
    <div class="page-body-content">
      <div class="selection-part-one">
        <titleBar title="全新数智化EMS2.0" :other="true"></titleBar>
        <div class="content">
          EMS2.0依托于我司自有专利的“物联边缘操作系统”技术创新研发，由全新EMS控制器、全新云平台、全新储能管理方案三大核心部分组成。
        </div>
      </div>
    </div>
    <div class="page-body-container">
      <div class="page-body-container-head">
        <div class="head-title-box">
          <div class="head-title-box-item" :class="active == 1 ? 'active' : ''" @click="onTabChange(1)">
            <img class="emsImg" src="https://cdn.lnxall.com/img/EMSController.png" alt="">
            EMS控制器
          </div>
          <div class="head-title-box-item" :class="active == 2 ? 'active' : ''" @click="onTabChange(2)">
            <img class="cloudImg" src="https://cdn.lnxall.com/img/cloud.png" alt="">
            智汇云储
          </div>
          <div class="head-title-box-item" :class="active == 3 ? 'active' : ''" @click="onTabChange(3)">
            <img class="schemeImg" src="https://cdn.lnxall.com/img/scheme.png" alt="">
            全新储能管理方案
          </div>
        </div>
        <template v-if="active == 1">
          <div class="head-title-content">EMS控制器采用高度集成一体化硬件设计、全国产工业级芯片，并加持自有专利的嵌入式低代码物联边缘操作系统。</div>
          <img class="ems-products-img" src="https://cdn.lnxall.com/img/EMSControllerPro.png" alt="">
        </template>
        <template v-if="active == 2">
          <div class="head-title-content">智汇云储由前台驾驶舱和后台管理两部分组成，兼顾大屏数据展示与批量操作管理，可进行深入智能化分析，实现系统排障更精准和收益策略最优化。</div>
          <img class="cloud-products-img" src="https://cdn.lnxall.com/img/cloudPro.png" alt="">
        </template>
        <template v-if="active == 3">
          <div class="head-title-content">全新储能管理方案首创了对等模式，用户可自行选择主从或对等模式，从容应对复杂落地场景，进一步增强了系统的灵活性和可靠性。</div>
          <img class="scheme-products-img" src="https://cdn.lnxall.com/img/schemePro.png" alt="">
        </template>
      </div>
    </div>
    <div class="advantage" v-if="active == 1">
      <div class="advantage-item">
        <img class="hardware-img" src="https://cdn.lnxall.com/img/iconHardware.png" alt="">
        <div class="advantage-item-title"><span>硬件优势</span></div>
        <div class="advantage-item-content">
          <div class="item-card">
            <p>自研硬件和国产工业级芯片</p>
            <p>提升数据处理能力</p>
          </div>
          <div class="item-card">
            <p>本地存储大量数据</p>
            <p>保障安全</p>
          </div>
          <div class="item-card">
            <p>低功耗</p>
            <p>提高系统能效和收益</p>
          </div>
          <div class="item-card">
            <p>小尺寸、低成本、丰富接口</p>
            <p>安装便捷</p>
          </div>
        </div>
      </div>
      <div class="advantage-item">
        <img class="software-img" src="https://cdn.lnxall.com/img/iconSoftware.png" alt="">
        <div class="advantage-item-title"><span>软件优势</span></div>
        <div class="advantage-item-content">
          <div class="item-card">
            <p>离线数据分析，提供细致数据</p>
            <p>支持系统优化</p>
          </div>
          <div class="item-card">
            <p>零配置自组网</p>
            <p>简化落地，降低成本</p>
          </div>
          <div class="item-card">
            <p>整柜智能诊断</p>
            <p>快速排查隐患，定位故障</p>
          </div>
          <div class="item-card">
            <p>批量配置固件</p>
            <p>提高生产效率</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cloudES" v-if="active == 2">
      <div class="cloudES-item">
        <img src="https://cdn.lnxall.com/img/RCEBG.png" alt="">
        <div class="cloudES-item-title">降本增效</div>
        <div class="cloudES-item-content">
          <p>满足生产、调试、运维等不同需求。</p>
          <p>实现高效批量集控、极致数据压缩和细致层级权限管理。</p>
        </div>
      </div>
      <div class="cloudES-item">
        <img src="https://cdn.lnxall.com/img/thoughtBG.png" alt="">
        <div class="cloudES-item-title">便捷运维</div>
        <div class="cloudES-item-content">
          <p>深入告警日志分析，全面巡检预警</p>
          <p>强大远程调试，智能 BI 分析</p>
        </div>
      </div>
      <div class="cloudES-item">
        <img src="https://cdn.lnxall.com/img/cloudESBG.png" alt="">
        <div class="cloudES-item-title">智能运维</div>
        <div class="cloudES-item-content">
          <p>收益估算与预测</p>
          <p>AI优化策略，资产监测与评估</p>
        </div>
      </div>
    </div>
    <div class="scheme-container" v-if="active == 3">
      <div class="scheme-container-item">
        <img class="deploy-ornament-img" src="https://cdn.lnxall.com/img/flex-deployment-dec.png" alt="">
        <div class="scheme-container-item-title">部署灵活</div>
        <img class="deploy-flexible-img" src="https://cdn.lnxall.com/img/flex-deployment.png" alt="">
        <div class="scheme-container-item-content">无需捆绑，可实现三方设备多元配置</div>
      </div>
      <div class="scheme-container-item">
        <img class="mode-ornament-img" src="https://cdn.lnxall.com/img/mode-selection-dec.png" alt="">
        <div class="scheme-container-item-title">模式自选</div>
        <img class="mode-flexible-img" src="https://cdn.lnxall.com/img/mode-selection.png" alt="">
        <div class="scheme-container-item-content">首创对等模式，从容应对复杂落地场景</div>
      </div>
      <div class="scheme-container-item">
        <img class="microgrid-ornament-img" src="https://cdn.lnxall.com/img/support-microgrid-dec.png" alt="">
        <div class="scheme-container-item-title">支持微网</div>
        <img class="microgrid-flexible-img" src="https://cdn.lnxall.com/img/support-microgrid.png" alt="">
        <div class="scheme-container-item-content">提供完善的微网控制管理能力</div>
      </div>
      <div class="scheme-container-item">
        <img class="func-ornament-img" src="https://cdn.lnxall.com/img/functional-dec.png" alt="">
        <div class="scheme-container-item-title">功能全包</div>
        <img class="func-flexible-img" src="https://cdn.lnxall.com/img/functional.png" alt="">
        <div class="scheme-container-item-content">从设计到运维的端到端全链条服务支撑</div>
      </div>
    </div>
  </div>
</template>

<script>
import titleBar from "@/components/titleBar";
export default {
  name: "intellect-page",
  components: {
    titleBar,
  },
  data() {
    return {
      active: 1,
      cardactive: true,
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {
    onTabChange(value) {
      this.active = value;
    },
  },
  destroyed() {},
};
</script>
<style scoped lang="scss">
.page-body {
  box-sizing: border-box;
  background-color: #ffffff;
  .page-body-content {
    margin: 0 auto;
    width: 1200px;
    max-width: 1200px;
    box-sizing: border-box;
    .selection-part-one {
      height: 180px;
      padding: 76px 0 60px 0;
      .content {
        overflow-wrap: break-word;
        color: #222222;
        font-size: 22px;
        letter-spacing: 1px;
        line-height: 48px;
        text-align: left;
        margin-top: 40px;
      }
    }
  }
  &-container {
    width: 100%;
    height: 820px;
    background: #F4F8F8;
    box-sizing: border-box;
    &-head {
      margin: 0 auto;
      width: 1200px;
      max-width: 1200px;
      box-sizing: border-box;
      position: relative;
      .head-title-box {
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 40px;
        width: 983px;
        height: 72px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &-item {
          font-weight: 400;
          font-size: 24px;
          color: #888888;
          line-height: 33px;
          letter-spacing: 1px;
          border-radius: 45px;
          padding: 20px 50px;
          cursor: pointer;
          .emsImg {
            vertical-align: -6px;
            margin-right: 10px;
          }
          .cloudImg {
            width: 28px;
            height: 30px;
            vertical-align: -8px;
            margin-right: 10px;
          }
          .schemeImg {
            width: 28px;
            height: 24px;
            vertical-align: -4px;
            margin-right: 10px;
          }
        }
        .active {
          font-weight: 500;
          color: #222222;
          background: #FFFFFF;
          box-shadow: 8px 0px 40px 0px rgba(183,188,205,0.17);
          
        }
      }
      .head-title-content {
        font-weight: 400;
        font-size: 22px;
        color: #222222;
        line-height: 48px;
        letter-spacing: 1px;
      }
      .ems-products-img {
        position: absolute;
        top: 184px;
        left: 50%;
        transform: translateX(-50%);
      }
      .cloud-products-img {
        position: absolute;
        top: 373px;
        left: 50%;
        transform: translateX(-50%);
      }
      .scheme-products-img {
        position: absolute;
        top: 365px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 1364px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('https://cdn.lnxall.com/img/whiteTexture.png');
    &-item {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1190px;
      height: 546px;
      .hardware-img {
        width: 50px;
        height: 38px;
      }

      .software-img {
        width: 40px;
        height: 40px;
      }

      &-title {
        width: 233px;
        height: 48px;
        margin-top: 10px;
        margin-bottom: 42px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #222222;
        line-height: 42px;
        letter-spacing: 2px;
        text-align: center;
        background: url("https://cdn.lnxall.com/img/titleEmbellishment.png") no-repeat;
        span {
          display: inline-block;
          margin-left: 3px;
          vertical-align: -6px;
        }
      }

      &-content {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        .item-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 570px;
          height: 180px;
          box-shadow: 0px 16px 24px 0px rgba(183,188,205,0.2);
          border-radius: 8px;
          background: url("https://cdn.lnxall.com/img/unselected-background.png") -24px -8px no-repeat;
          p {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #555555;
            line-height: 2px;
            text-align: center;
          }
        }

        .item-card:hover {
          background: url("https://cdn.lnxall.com/img/select-background.png") -24px -8px no-repeat;
          p {
            font-weight: 600;
            font-size: 22px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .cloudES {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 1324px;
    &-item {
      margin-top: 52px;
      width: 1200px;
      max-width: 1200px;
      height: 340px;
      position: relative;
      &-title {
        position: absolute;
        top: 88px;
        left: 517px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #222222;
        line-height: 42px;
        text-align: center;
      }
      &-content {
        position: absolute;
        top: 158px;
        left: 516px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #555555;
        line-height: 30px;
      }
    }
  }
  .scheme-container {
    &-item:nth-child(odd) {
      background: linear-gradient(to top, #F9FFFE 0%, #FFFFFF 100%);
    }
    &-item:nth-child(even) {
      background: #F4F8F8;
    }
    &-item {
      width: 100%;
      height: 460px;
      box-sizing: border-box;
      position: relative;
      .deploy-ornament-img {
        width: 51px;
        height: 39px;
        position: absolute;
        top: 185px;
        left: 463px;
      }
      &-title {
        position: absolute;
        top: 210px;
        left: 517px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #247777;
        line-height: 42px;
      }
      .deploy-flexible-img {
        width: 291px;
        height: 291px;
        position: absolute;
        top: 92px;
        left: 818px;
      }
      &-content {
        position: absolute;
        top: 216px;
        left: 1185px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 20px;
        color: #555555;
        line-height: 30px;
      }
      .mode-ornament-img {
        width: 49px;
        height: 37px;
        position: absolute;
        top: 186px;
        left: 464px;
      }
      .mode-flexible-img {
        width: 273px;
        height: 264px;
        position: absolute;
        top: 102px;
        left: 828px;
      }
      .microgrid-ornament-img {
        width: 63px;
        height: 51px;
        position: absolute;
        top: 181px;
        left: 459px;
      }
      .microgrid-flexible-img {
        width: 320px;
        height: 307px;
        position: absolute;
        top: 77px;
        left: 803px;
      }
      .func-ornament-img {
        width: 53px;
        height: 47px;
        position: absolute;
        top: 178px;
        left: 462px;
      }
      .func-flexible-img {
        width: 294px;
        height: 269px;
        position: absolute;
        top: 88px;
        left: 814px;
      }
    }
  }
}
</style>
