function changeInnerHtml(el, binding, targetElement = el) {
  let start = 0;
  let end = 0;
  if (typeof binding.value === 'number') {
    end = binding.value;
  } else if (Array.isArray(binding.value)) {
    end = binding.value[0];
  }
  let sumSecond = 2000;
  let range = end - start;

  if (range == 0) return (el.innerHTML = '0');

  let step = sumSecond / range; // 每次加1的时间间隔，单位ms
  let increment = 1; // 变化的量
  if (step < 20) {
    step = 20;
    increment = Math.floor(end / step);
  }

  let current = start;
  let timer;

  const startAnimation = () => {
    clearInterval(timer);
    timer = setInterval(() => {
      if (current + increment >= end) {
        current = end;
        clearInterval(timer);
      } else {
        current += increment;
      }
      el.innerHTML = current;
    }, step);
    el.timer = timer;
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        startAnimation(); // 当元素可见时开始动画
        obs.unobserve(targetElement); // 动画开始后不再观察
      }
    });
  });

  observer.observe(targetElement); // 开始观察当前元素
}

export default {
  inserted(el, binding) {
    let targetElement = el;
    if (Array.isArray(binding.value) && binding.value.length > 1) {
      targetElement = document.querySelector(binding.value[1]);
    }
    changeInnerHtml(el, binding, targetElement);
  },
  update(el, binding) {
    let targetElement = el;
    if (Array.isArray(binding.value) && binding.value.length > 1) {
      targetElement = document.querySelector(binding.value[1]);
    }
    changeInnerHtml(el, binding, targetElement);
  },
  unbind(el) {
    clearInterval(el.timer);
  },
};
