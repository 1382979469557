<template>
  <div class="web-footer-page">
    <div class="footer-content">
      <div class="leet-content">
         <div class="leftbox">
           <div class="logo">
            <img src="https://cdn.lnxall.com/logo%402x.png">
           </div>
           <div class="leet-item">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAWlBMVEX///8AAAD///////8KEyT///////////9ARlQwN0b////////////////////R1NfFxss5Pk3///////////////////////////////////////////////8gnk2KAAAAHnRSTlPMAEcHzE80HszMpol5YD3MzMzHvraSgW5UJxkPqmhno8Z6AAAAdklEQVQI1zXOSRaDMAwE0UKeMAYzz8n9r5lYJL3S39QTVdklculRaFqAzfy4oUsPT+jruodT2UFt7Qjdn6O1Ed7KDEOMA2SladA15imHGWAOWjay+wkmv4upCAukDJJgCQhA65x+JlSulNYVeDlN3V6OQ/z9PT97RAP5pwU+BQAAAABJRU5ErkJggg=="><div class="title">杭州市西湖区华星路99号创业大厦（华星路）西7楼</div>
           </div>
           <div class="leet-item">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAAXVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9RKvvlAAAAH3RSTlMAzA0GxJMvontuaGFTSzQfGRTBubSqpHNYKSWLg0M/u7/WawAAAHdJREFUCNdNzdkOgzAMRNE7JGGnJOzQ5f8/s3JbVM6LZdmj4Su7RS5yacl/B8DlW6EK6EftkLroGrWwSOvnuab0kVl6WKRWlw0VlXRg7jOhIHnvMJvcOkGjEtO/OKwkqOEiK1TvYSr7cw8yA6d2lPzzH3Bdm2y+Aa5HA/vNiPOCAAAAAElFTkSuQmCC"><div class="title">0571-87698273</div>
           </div>
           <div class="leet-item">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAMAAABlXnzoAAAARVBMVEX///////////////////////////8AAAD////////////////////////////////////////////////////////////l9IKsAAAAF3RSTlPMWFN4hykFAL+cgkU2Ew3Gs6swIpJgQR1TFpoAAABTSURBVAjXVc5JDoAgAMDAKrILsqj/f6ohIQi9za1ouY+q5mXqxJtfqRCD6LgeFXAiStvkQr0FB2ZTPlkZxUUj5FOp3UAn2AwTodOt9OuGLttI6g/4yAJlIMPRuQAAAABJRU5ErkJggg=="><div class="title">Sales@Lnxall.com</div>
           </div>
         </div>
         <div class="rightbox">
          <div class="ewmbox">
            <img src="https://cdn.lnxall.com/ewm%402x.png">
          </div>
         </div>
      </div>
    </div>
    <div class="footer-description">版权所有©&nbsp;浙江简捷物联科技有限公司&nbsp;|&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备20014292号-1</a></div>
  </div>
</template>

<script>
export default {
  name: "webFooter",
  data() {
    return {};
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="scss">
.web-footer-page {
  height: 306px;
  box-sizing: border-box;
  .footer-content {
    height: 250px;
    box-sizing: border-box;
    background: #060c18;
    .leet-content{
      margin: 0 auto;
      width: 1200px;
      max-width: 1200px;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .leftbox{
        width: 800px;
        .logo{
          width: 75px;
          height: 50px;
          margin-top: 41px;
          img{
            width: 100%;
          }
        }
        .leet-item{
          margin-top: 24px;
          height: 17px;
          line-height: 17px;
          position: relative;
          color: rgba(255,255,255,0.8);
          font-size: 12px;
          img{
            width: 14px;
            height: 14px;
            position: absolute;
            top: 1px;
          }
          .title{
            position: absolute;
            left: 25px;
            width: 100%;
          }
        }
      }
      .rightbox{
        width: 400px;
        position: relative;
        .ewmbox{
          position: absolute;
           top: 56px;
           right: 0;
           width: 138px;
           height: 138px;
           img{
            width: 100%;
           }
        }
      }
    }
  }
  .footer-description {
    height: 56px;
    line-height: 56px;
    box-sizing: border-box;
    background: #040811;
    text-align: center;
    color: rgba(255,255,255,0.6);
    font-size: 14px;
    a{
      color: rgba(255,255,255,0.6);
      text-decoration: none;
    }
  }
}
</style>
