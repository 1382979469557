import Vue from 'vue'
import App from './App.vue'
import { Carousel,CarouselItem } from 'element-ui';
import "animate.css";
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import Directives from "@/util/directive";
Vue.config.productionTip = false
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Directives)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
